import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "../../../config.js";
import SelectJs from "../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";
import SweetAlert from "sweetalert2-react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import "react-datepicker/dist/react-datepicker.css";
import {
  UserEditSkeleton,
  UserDetailSkeleton,
  UserPrintSkeleton,
  UserButtonLoading,
} from "../../partials/loadings/ComponentLoading.js";

// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

export default class Orangtua extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url Api
      url_table: `${conf.uri_backend}api/v1/user/table/OW/`,
      url_detail: `${conf.uri_backend}api/v1/user/edit/OW/`,
      url_token_user: `${conf.uri_backend}api/v1/user/update_token/OW`,
      url_generate_all_token: `${conf.uri_backend}api/v1/user/generate-all-token/OW`,
      url_table_token: `${conf.uri_backend}api/v1/user/table_token/OW`,
      url_print_token: `${conf.uri_backend}api/v1/user/generete_token/OW`,
      url_export_data: `${conf.uri_backend}api/v1/user/export/OW/`,
      url_count_table: `${conf.uri_backend}api/v1/user/count_table/OW`,

      // bind data
      id_generate: "",
      tahunSekarang: new Date().getFullYear(),

      // modal
      modal_detail: false,
      modal_cetak: false,

      // from DB
      data_tahun_ajaran: [],

      // for data modal
      data_print: [],
      data_detail: [],

      // gender array
      jenis_kelamin_text: ["Perempuan", "Laki - laki"],
      // ortu array
      jenis_ortu_text: [],

      tahun_ajaran_search: "",

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      // session var
      institusi_id_select: "",
      jenjang_id_select: "",
      npsn_id_select: "",
      data_session: [],

      user_id: "",

      mode_show: 10,
      query: "",
      totalUser: "",

      queryToken: "",
      mode_show_token: 10,
      searchToken: "",
      selectStatus: "",
      loading_generate_token: false,
    };

    // Token Function
    this.generate_token = this.generate_token.bind(this);
    this.generateAllToken = this.generateAllToken.bind(this);
    this.id_generate = this.id_generate.bind(this);

    // Detail Function
    this.showModalDetail = this.showModalDetail.bind(this);
    this.hideModalDetail = this.hideModalDetail.bind(this);

    // Cetak Function
    this.showModalCetak = this.showModalCetak.bind(this);
    this.hideModalCetak = this.hideModalCetak.bind(this);
    this.printToken = this.printToken.bind(this);
    this.showTableToken = this.showTableToken.bind(this);

    // export
    this.export = this.export.bind(this);

    // Reset Search Function
    this.resetSearch = this.resetSearch.bind(this);
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);

    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);

    // set filter
    this.setFilter = this.setFilter.bind(this);

    this.showMode = this.showMode.bind(this);
    this.showModeToken = this.showModeToken.bind(this);
    this.showCount = this.showCount.bind(this);
  }
  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    npsn_id_select: res.data.npsn,
                  },
                  function() {
                    this.dt.fetchEntities(
                      `&query=${this.state.query}&tahun_ajaran=${this.state.tahun_ajaran_search}&paginate=${this.state.mode_show}`
                    );
                    this.showCount();
                  }
                );
                this.getTahunAjaranNow();
              });
          });
        });
    }
  }

  showCount() {
    axios
      .get(
        this.state.url_count_table +
          "?institusi=" +
          this.state.institusi_id_select,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ totalUser: res.data });
      });
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 405:
        message =
          "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  showMode(num) {
    this.setState({ mode_show: num }, function() {
      this.dt.loading();
      this.dt.resetPage(
        `&paginate=${this.state.mode_show}&query=${this.state.query}`
      );
      this.setState({ loading_saveprint: false });
    });
  }

  showModeToken(num) {
    this.setState({ mode_show_token: num }, function() {
      this.dtt.resetPage(
        `&paginate=${this.state.mode_show_token}&search=${this.state.searchToken}`
      );
      this.dtt.loading();
    });
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  id_generate(e) {
    this.setState({ id_generate: e.target.value });
  }

  // Modal Detail
  showModalDetail(data) {
    this.setState({ loading_detail: true, modal_detail: true });
    axios
      .get(this.state.url_detail + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        this.setState({ data_detail: res.data.data, loading_detail: false });
      });
    console.log(this.state.data_tahun_ajaran);
  }
  hideModalDetail() {
    this.setState({ modal_detail: false });
  }

  // Cetak Function
  showModalCetak() {
    this.setState(
      {
        modal_cetak: true,
        queryToken: `&paginate=10&search=`,
      },
      function() {
        this.dtt.fetchEntities(this.state.queryToken);
      }
    );
    // axios.get(`${this.state.url_table_token}/${this.state.institusi_id_select}?paginate=10`, {headers:{Authorization:tokenApi}}).then(res => {
    //   this.setState({ data_print: res.data, loading_print: false });
    // });
  }

  showTableToken() {
    if (this.state.queryToken != "") {
      return (
        <div>
          <DataTable
            ref={(dtt) => {
              this.dtt = dtt;
            }}
            action={{
              pilih: (col, data) => {
                return (
                  <input
                    type="checkbox"
                    name="check_data[]"
                    className="check"
                    id="check_data"
                    value={data.id}
                    defaultChecked={this.state.PilihSemua ? true : null}
                  />
                );
              },
            }}
            url={
              this.state.url_table_token + "/" + this.state.institusi_id_select
            }
            columns={[
              {
                index: "kode_pendaftaran",
                label: "Kode Pendaftaran",
              },
              {
                index: "token",
                label: "token",
              },
              {
                index: "nama",
                label: "nama",
              },
              {
                index: "jenis_orangtua",
                label: "Jenis Orang Tua",
              },
              {
                index: "pilih",
                label: "pilih",
              },
            ]}
          />
        </div>
      );
    }
  }

  hideModalCetak() {
    this.setState({
      modal_cetak: false,
      queryToken: "",
      pilihSemua: false,
      searchToken: "",
      mode_show_token: 10,
    });
  }

  printToken(e) {
    e.preventDefault();
    this.setState({ loading_saveprint: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = "";
    var str = "";
    console.log(str + "tes");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals += "," + checkboxes[i].value;
        str = vals.substr(1);
      }
    }

    if (str == "") {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "error",
        message_alert: "Pilih atau centang User terlebih dahulu",
        loading_saveprint: false,
      });
    } else {
      axios
        .post(
          this.state.url_print_token,
          { id: str },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ loading_saveprint: false });
          var mywindow = window.open("", "new div", "height=400,width=600");
          res.data.map((person, index) => {
            var jenis = "";
            if (person.jenis_orangtua == "Ayah") {
              jenis = "Ayah";
            } else {
              jenis = "Ibu";
            }
            this.setState({ jenis_ortu_text: jenis });
            mywindow.document.write(
              `<html>
              <head>
              </head>
              <body style="display: flex; flex-wrap: wrap; align-content: flex-start;">
                <table style="width:48%; margin-bottom:10px; margin-left: 10px; border-radius: 8px; padding: 4px;" border="1px solid #000000">
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">KODE PENDAFTARAN</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${person.kode_pendaftaran}</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">TOKEN</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${person.token}</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">NAMA SISWA</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${person.nama}</td>
                  </tr>
                  <tr>
                    <td style="border: 0px solid #000000; font-size: 11px;">JENIS ORTU</td>
                    <td style="border: 0px solid #000000; font-size: 11px; width: 60%;">: ${jenis}</td>
                  </tr>
                </table>
              </body>
            </html>`
            );
          });
          mywindow.print();
          mywindow.close();
        })
        .catch((err) => {
          this.alertMessage(err.response);
          this.setState({ loading_saveprint: false });
        });
    }
  }

  // Generate All Token
  generateAllToken() {
    this.setState({ loading_generate_token: true });
    this.setState({ loading_token: true });
    axios
      .post(
        this.state.url_generate_all_token,
        { user_institusi_id: this.state.institusi_id_select },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_generate_token: false });
        this.setState({ loading_token: false });
        if (res.data.status === true) {
          this.dt.loading();
          this.dt.resetPage(
            `&status=${this.state.selectStatus}&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        } else {
          swal.fire({
            title: "Gagal",
            text: "Ada Kesalahan",
            icon: "error",
          });
        }
        this.setState({ loading_token: false });
      });
  }

  // Generate Token
  generate_token(data) {
    this.setState({ loading_token: data });
    axios
      .post(
        this.state.url_token_user,
        { user_id: data },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_token: false });
        document.getElementById("token" + data + "").innerHTML =
          "" + res.data.token + "";
        var element = document.getElementById("myButton" + data + "");
        element.classList.add("hidden");
      });
  }

  // reset search
  resetSearch(event) {
    event.preventDefault();
    document.getElementById("search").value = "";
    this.dt.fetchEntities(
      `&query=${this.state.query}&tahun_ajaran=${this.state.tahun_ajaran_search}&paginate=${this.state.mode_show}`
    );
  }

  export(event) {
    this.setState({ loading_export: true });
    event.preventDefault();
    axios({
      url: this.state.url_export_data + this.state.institusi_id_select,
      method: "GET",
      responseType: "blob", // important
      headers: { Authorization: tokenApi },
    })
      .then((response) => {
        this.setState({ loading_export: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var tanggal = new Date();
        var bulan = tanggal.getMonth() + 1;
        if (bulan < 10 ? (bulan = `0${bulan}`) : "");
        link.setAttribute(
          "download",
          "Ortu_" +
            tanggal.getDate() +
            "-" +
            bulan +
            "-" +
            tanggal.getFullYear() +
            "_TIME(" +
            tanggal.getHours() +
            "-" +
            tanggal.getMinutes() +
            ").xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.alertMessage(err);
        this.setState({ loading_export: false });
      });
  }

  filterTahunAjaran(e) {
    if (e) {
      this.setState({ tahun_ajaran_search: e.value });
    } else {
      this.setState({ tahun_ajaran_search: "" });
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.dt.loading();
    this.dt.resetPage(
      `&query=${this.state.query}&tahun_ajaran=${this.state.tahun_ajaran_search}&paginate=${this.state.mode_show}`
    );
  }

  changeStatus = (e) => {
    if (e) {
      this.setState(
        {
          selectStatus: e.value,
        },
        function() {
          this.dt.loading();
          this.dt.resetPage(
            `&status=${this.state.selectStatus}&paginate=${this.state.mode_show}&query=${this.state.query}`
          );
        }
      );
    } else {
      this.dt.loading();
      this.dt.resetPage(
        `&paginate=${this.state.mode_show}&query=${this.state.query}`
      );
    }
  };

  pilihSemua() {
    var checkboxes = document.getElementsByClassName("select-all").item(0)
      .checked;
    if (checkboxes == true) {
      this.setState({ PilihSemua: true }, function() {
        this.dtt.loading();
        this.dtt.fetchEntities(this.state.queryToken);
      });
    } else {
      this.setState({ PilihSemua: false }, function() {
        this.dtt.loading();
        this.dtt.fetchEntities(this.state.queryToken);
      });
    }
  }

  render() {
    return (
      <div>
        <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              User Orang Tua / Wali
              <span className="text-gray-500 font-thin block text-sm">
                Daftar User Orang Tua / Wali.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                Pelajari Lebih Lanjut ?
              </a> */}
              </span>
            </h1>
            <div className="flex">
              {this.state.institusi_id_select != "" ? (
                <>
                  <button
                    onClick={this.generateAllToken}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                    disabled={this.state.loading_generate_token ? true : false}
                  >
                    {this.state.loading_export ? (
                      <UserButtonLoading color="text-gray-700" />
                    ) : (
                      <>
                        <i className="bx bx-doughnut-chart text-lg mr-1"></i>{" "}
                        Generate Token
                      </>
                    )}
                  </button>
                  <button
                    onClick={this.export}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                    disabled={this.state.loading_export == true ? true : false}
                  >
                    {this.state.loading_export ? (
                      <UserButtonLoading color="text-gray-700" />
                    ) : (
                      <>
                        <i className="bx bx-export text-lg mr-1"></i> Export
                      </>
                    )}
                  </button>
                  <button
                    onClick={this.showModalCetak}
                    className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm"
                  >
                    <i className="bx bx-printer text-lg mr-1"></i> Cetak
                  </button>
                </>
              ) : (
                <>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                  <div className="w-20 px-2">
                    {" "}
                    <Skeleton height={20} className="bg-gray-400" />{" "}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        {/* modal detail */}
        <Modal
          size="lg"
          show={this.state.modal_detail}
          onHide={this.hideModalDetail}
        >
          {this.state.loading_detail ? (
            <UserDetailSkeleton />
          ) : (
            <Modal.Body className="">
              <div className="flex flex-col w-full">
                <div
                  className={`w-full rounded-lg bg-1 mb-10`}
                  style={{ height: "10rem" }}
                >
                  <div
                    className="overflow-hidden rounded-lg"
                    style={{ height: "10rem" }}
                  >
                    <img
                      className=""
                      src={
                        this.state.data_detail.foto_sampul
                          ? `https://static.schoolmedia.id/public/assets/socmed/OW/cover/${this.state.data_detail.foto_sampul}`
                          : `${conf.uri_frontend}assets/img/cover-unavailable.svg`
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="flex flex-row px-4 pb-4 rounded-lg items-center w-full bg-transparent-grad relative"
                    style={{ bottom: "10rem", paddingTop: "6rem" }}
                  >
                    <img
                      className="rounded-full border-4 border-white shadow-xl"
                      style={{
                        width: "6rem",
                        height: "6rem",
                        objectFit: "cover",
                      }}
                      src={
                        this.state.data_detail.foto_profil
                          ? `https://static.schoolmedia.id/public/assets/socmed/OW/photo/${this.state.data_detail.foto_profil}`
                          : `${conf.uri_frontend}assets/img/profile-unavailable.png`
                      }
                    />
                    <h1 className="text-xl font-bold text-gray-800 ml-2">
                      {this.state.data_detail.nama
                        ? this.state.data_detail.nama
                        : "-"}
                    </h1>
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="detail1"
                id="uncontrolled-tab-example"
                className="mt-4"
              >
                <Tab eventKey="detail1" title="Detail 1">
                  <div className="show-grid flex">
                    <div className="sm:w-full w-1/2">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Token
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.token
                            ? this.state.data_detail.token
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Jenis Orangtua
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.jenis_orangtua
                            ? this.state.data_detail.jenis_orangtua
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No Pendaftaran
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.kode_pendaftaran
                            ? this.state.data_detail.kode_pendaftaran
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Tempat Lahir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.tempat_lahir
                            ? this.state.data_detail.tempat_lahir
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Tanggal Lahir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.tanggal_lahir
                            ? this.state.data_detail.tanggal_lahir
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/2">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Jenis Kelamin
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.jenis_kelamin != null
                            ? this.state.data_detail.jenis_kelamin != 1
                              ? "Perempuan"
                              : "Laki - laki"
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Agama
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.agama
                            ? this.state.data_detail.agama
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. Handphone
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.no_handphone
                            ? this.state.data_detail.no_handphone
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Golongan Darah
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.golongan_darah
                            ? this.state.data_detail.golongan_darah
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Pendidikan Terakhir
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.pendidikan_terakhir
                            ? this.state.data_detail.pendidikan_terakhir
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="detail2" title="Detail 2">
                  <div className="show-grid flex">
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Motto
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.motto
                            ? this.state.data_detail.motto
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. KK
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.kk
                            ? this.state.data_detail.kk
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. NIK
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.nik
                            ? this.state.data_detail.nik
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. NPWP
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.npwp
                            ? this.state.data_detail.npwp
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          No. Paspor
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.paspor
                            ? this.state.data_detail.paspor
                            : "-"}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-700 block text-xs">
                          Negara
                        </label>
                        <p className="mb-2 ml-2 text-gray-900 block text-base">
                          {this.state.data_detail.negara
                            ? this.state.data_detail.negara
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-900 block text-sm">
                          Alamat Tinggal
                        </label>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Provinsi
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.provinsi_tinggal
                              ? this.state.data_detail.provinsi_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kabupaten / Kota
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kabupaten_tinggal
                              ? this.state.data_detail.kabupaten_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kecamatan
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kecamatan_tinggal
                              ? this.state.data_detail.kecamatan_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Desa
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.desa_tinggal
                              ? this.state.data_detail.desa_tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kode Pos
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kode_pos__tinggal
                              ? this.state.data_detail.kode_pos__tinggal
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            RW / RT
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.rw_tinggal
                              ? this.state.data_detail.rw_tinggal
                              : "-"}{" "}
                            /
                            {this.state.data_detail.rt_tinggal
                              ? ` ${this.state.data_detail.rt_tinggal}`
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Alamat
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.alamat_tinggal
                              ? this.state.data_detail.alamat_tinggal
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sm:w-full w-1/3">
                      <div className="w-full mt-3">
                        <label className="mb-1 ml-2 text-gray-900 block text-sm">
                          Alamat Domisili
                        </label>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Provinsi
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.provinsi_domisili
                              ? this.state.data_detail.provinsi_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kabupaten / Kota
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kabupaten_domisili
                              ? this.state.data_detail.kabupaten_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kecamatan
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kecamatan_domisili
                              ? this.state.data_detail.kecamatan_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Desa
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.desa_domisili
                              ? this.state.data_detail.desa_domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Kode Pos
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.kode_pos__domisili
                              ? this.state.data_detail.kode_pos__domisili
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            RW / RT
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.rw_domisili
                              ? this.state.data_detail.rw_domisili
                              : "-"}{" "}
                            /
                            {this.state.data_detail.rt_domisili
                              ? ` ${this.state.data_detail.rt_domisili}`
                              : "-"}
                          </p>
                        </div>
                        <div className="pl-2">
                          <label className="mb-1 ml-2 text-gray-700 block text-xs">
                            Alamat
                          </label>
                          <p className="mb-2 ml-2 text-gray-900 block text-base">
                            {this.state.data_detail.alamat_domisili
                              ? this.state.data_detail.alamat_domisili
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <div className="show-grid flex mt-4 border-t">
                <div className="sm:w-full w-1/2 px-2">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto KTP
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_ktp
                        ? `https://static.schoolmedia.id/public/assets/socmed/OW/ktp/${this.state.data_detail.foto_ktp}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
                <div className="sm:w-full w-1/2 px-2">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto KTP & Wajah
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_ktp_dan_wajah
                        ? `https://static.schoolmedia.id/public/assets/socmed/OW/ktp-face/${this.state.data_detail.foto_ktp_dan_wajah}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="rounded-lg w-1/2 mx-auto">
                  <p className="my-2 ml-2 text-gray-900 block text-sm font-bold">
                    Foto Doc. Pendukung
                  </p>
                  <img
                    className="w-full"
                    style={{
                      height: "300px",
                      objectFit: "none",
                      objectPosition: "center",
                    }}
                    src={
                      this.state.data_detail.foto_doc_pendukung
                        ? `https://static.schoolmedia.id/public/assets/socmed/OW/img-support/${this.state.data_detail.foto_doc_pendukung}`
                        : `${conf.uri_frontend}assets/img/img-unavailable.png`
                    }
                  />
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalDetail}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        <div className=" shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border sm-container">
          {this.state.institusi_id_select != "" ? (
            <>
              <div className="flex w-full flex-wrap items-center mt-3 mb-2 px-4 pb-2">
                <button
                  className="btn-blue px-4 mr-1 flex items-center rounded-full text-white py-2 text-xs"
                  onClick={() => {
                    this.setState({
                      showFilter: !this.state.showFilter,
                    });
                  }}
                >
                  <i className="bx bx-filter text-lg mr-1"></i>
                  Pilih
                </button>
                <div className="relative w-2/4 flex items-center">
                  <input
                    type="search"
                    className="w-1/2 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                    placeholder="Cari kode pendaftaran siswa, nama siswa/orangtua, username, token"
                    id="search"
                    onChange={(event) => {
                      this.setState({ query: event.target.value }, function() {
                        this.dt.loading();
                        this.dt.resetPage(
                          `&query=${this.state.query}&tahun_ajaran=${this.state.tahun_ajaran_search}&paginate=${this.state.mode_show}`
                        );
                      });
                    }}
                  />
                  <div className="absolute top-1 left-0 inline-flex items-center py-2 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-gray-400"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        stroke="none"
                      ></rect>
                      <circle cx="10" cy="10" r="7"></circle>
                      <line x1="21" y1="21" x2="15" y2="15"></line>
                    </svg>
                  </div>
                  <div className="relative w-2/3 flex flex-row ml-2">
                    <Select
                      styles={selectStyles}
                      className="react-select__control w-100"
                      placeholder="Pilih Status"
                      isDisabled={this.state.disabledFilter}
                      defaultValue={
                        this.state.selectStatus != ""
                          ? {
                              value: this.state.selectStatus,
                              label: this.state.selectStatus,
                            }
                          : ""
                      }
                      options={[
                        { value: "Active", label: "Active" },
                        { value: "Pending", label: "Pending" },
                      ]}
                      onChange={(e) => {
                        this.changeStatus(e);
                      }}
                    />
                  </div>
                  <span className="text-sm text-gray-800 px-1 flex items-center">
                    Total data :{" "}
                    {this.state.totalUser != "" ? (
                      this.state.totalUser
                    ) : (
                      <span className="w-5 mt-1 ml-2">
                        {" "}
                        <Skeleton height={10} className="bg-gray-400" />{" "}
                      </span>
                    )}
                  </span>
                </div>
                <div className="w-1/4 text-right ml-auto">
                  <span className="text-sm text-gray-800 px-1 mb-2">
                    Tampilkan
                  </span>
                  <div className="w-1/2 ml-auto flex flex-wrap text-right items-center justify-end">
                    <div className="mr-0 flex text-right ">
                      <button
                        onClick={this.showMode.bind(this, 10)}
                        className={`${
                          this.state.mode_show == 10
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                      >
                        10
                      </button>
                      <button
                        onClick={this.showMode.bind(this, 50)}
                        className={`${
                          this.state.mode_show == 50
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                      >
                        50
                      </button>
                      <button
                        onClick={this.showMode.bind(this, 100)}
                        className={`${
                          this.state.mode_show == 100
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                      >
                        100
                      </button>
                      <button
                        onClick={this.showMode.bind(this, 200)}
                        className={`${
                          this.state.mode_show == 200
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                      >
                        200
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex w-full border-t flex-wrap items-center my-1 pb-2 pt-2">
                {this.state.showFilter ? (
                  <div className="flex w-full flex-wrap items-center px-4 pb-2 ">
                    <div className="py-2">
                      <Select
                        id="filterTahunAjaran"
                        styles={selectStyles}
                        isClearable
                        options={this.state.data_tahun_ajaran}
                        onChange={this.filterTahunAjaran}
                        placeholder={"Tahun Ajaran"}
                      />
                    </div>
                    <button
                      onClick={this.setFilter}
                      className="flex btn-schoolmedia text-sm text-white ml-2 px-4 py-2 rounded-full items-center"
                    >
                      Cari
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="w-full flex justify-between">
              <div className="w-1/2 px-2">
                <div className="w-1/2 px-2">
                  <Skeleton height={20} className="bg-gray-400" />
                  <Skeleton height={20} className="bg-gray-400" />
                </div>
              </div>
              <div className="w-1/2 px-2 flex justify-end">
                <div className="w-1/2 px-2">
                  <Skeleton height={20} className="bg-gray-400" />
                  <Skeleton height={20} className="bg-gray-400" />
                </div>
              </div>
            </div>
          )}

          {/* Cetak Modal */}
          <Modal
            size="lg"
            show={this.state.modal_cetak}
            onHide={this.hideModalCetak}
          >
            <Modal.Header>Cetak Orang Tua</Modal.Header>
            <Modal.Body>
              <div className="flex justify-between items-center w-full px-2 py-2">
                <div className="w-1/2 flex items-center justify-start">
                  <input
                    type="search"
                    className="w-2/3 pl-4 pr-1 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm "
                    placeholder="Cari nama"
                    id="search"
                    onChange={(event) => {
                      this.setState(
                        {
                          queryToken: `&search=${event.target.value}
                                    &paginate=${this.state.mode_show_token}`,
                          searchToken: event.target.value,
                        },
                        function() {
                          this.dtt.loading();
                          this.dtt.fetchEntities(this.state.queryToken);
                        }
                      );
                    }}
                  />
                  <div className="ml-2 w-1/3 pl-3 pr-3 py-1 rounded-full flex justify-between items-center  border border-gray-500 focus:outline-none focus:shadow-outline  text-sm ">
                    <span className="text-sm text-gray-800">Pilih semua</span>
                    <input
                      type="checkbox"
                      className="select-all"
                      onChange={this.pilihSemua.bind(this)}
                    />
                  </div>
                </div>
                <div className="w-1/2 text-right flex items-center">
                  <div className="w-full ml-1 flex flex-wrap text-right items-center justify-end">
                    <div className="mr-0 flex text-right items-center">
                      <span className="text-sm  ml-auto text-gray-800 px-1 mb-2">
                        Tampilkan :{" "}
                      </span>
                      <button
                        onClick={this.showModeToken.bind(this, 10)}
                        className={`${
                          this.state.mode_show_token == 10
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                      >
                        10
                      </button>
                      <button
                        onClick={this.showModeToken.bind(this, 50)}
                        className={`${
                          this.state.mode_show_token == 50
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                      >
                        50
                      </button>
                      <button
                        onClick={this.showModeToken.bind(this, 100)}
                        className={`${
                          this.state.mode_show_token == 100
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                      >
                        100
                      </button>
                      <button
                        onClick={this.showModeToken.bind(this, 200)}
                        className={`${
                          this.state.mode_show_token == 200
                            ? `btn-schoolmedia`
                            : `bg-gray-400`
                        } px-1 flex items-center rounded-full text-white py-1 text-xs mr-1`}
                      >
                        200
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="cetakToken">
                {this.showTableToken()}
                {this.state.loading_saveprint ? (
                  <button
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white mt-2 py-2 text-sm"
                    disabled={
                      this.state.loading_saveprint == true ? true : false
                    }
                  >
                    <UserButtonLoading />
                  </button>
                ) : (
                  <input
                    type="button"
                    onClick={this.printToken.bind(this)}
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white mt-2 py-2 text-sm"
                    value="Simpan"
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
                onClick={this.hideModalCetak}
              >
                Tutup
              </button>
            </Modal.Footer>
          </Modal>

          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              action={{
                jenis_kelamin: (col, data) => {
                  var jekel = "";
                  if (col.jenis_kelamin == 1) {
                    jekel = "Laki - Laki";
                  }

                  if (col.jenis_kelamin == 0) {
                    jekel = "Perempuan";
                  }

                  if (col.jenis_kelamin == null) {
                    jekel = "Belum diketahui";
                  }
                  return <p className="text-sm">{jekel}</p>;
                },

                jenis_orangtua: (col, data) => {
                  var jenis_or = "";
                  if (col.jenis_orangtua == "Ayah") {
                    jenis_or = "Ayah";
                  } else if (col.jenis_orangtua == "Ibu") {
                    jenis_or = "Ibu";
                  } else {
                    jenis_or = "";
                  }
                  return <p className="text-sm">{jenis_or}</p>;
                },

                created_at: (col, data) => {
                  if (col.created_at !== null) {
                    var date = "";
                    var month = "";
                    var year = "";
                    date = col.created_at.substr(8, 2);
                    month = col.created_at.substr(5, 2);
                    year = col.created_at.substr(0, 4);

                    return date + "-" + month + "-" + year;
                  } else {
                    return "Tidak Diketahui";
                  }
                },

                status: (col, data) => {
                  var statusUsr = "";
                  var tooltip = "";
                  var warna = "";
                  var ikon = "";
                  var width = "";

                  if (col.status == "Active") {
                    statusUsr = "Aktif";
                    tooltip = "User sudah teraktivasi";
                    warna = "bg-green-600";
                    ikon = "bx-check";
                    width = "w-16";
                  } else if (col.status == "Non-Active") {
                    statusUsr = "Non-Aktif";
                    tooltip = "User telah dinon-aktifkan";
                    warna = "bg-red-600";
                    ikon = "bx-x";
                    width = "w-auto";
                  } else {
                    statusUsr = "Pending";
                    tooltip =
                      "User belum aktivasi di SSO dan klik link konfirmasi di email";
                    warna = "bg-gray-600";
                    ikon = "bx-error";
                    width = "w-20";
                  }
                  return (
                    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
                      <div
                        className={`py-1 ${width} px-1 ${warna} text-white text-xs rounded-full flex cursor`}
                      >
                        <i
                          className={`bx ${ikon} text-white font-bold text-sm mx-1`}
                        ></i>
                        {statusUsr}
                      </div>
                    </OverlayTrigger>
                  );
                },

                token: (col, data) => {
                  var button = "";
                  if (col.token === null) {
                    button = (
                      <div>
                        <div id={"token" + data.id}></div>
                        <button
                          onClick={this.generate_token.bind(this, data.id)}
                          id={"myButton" + data.id}
                          className="font-medium btn-default text-gray-700 px-4 py-2 mr-1 rounded-full text-sm"
                          disabled={
                            this.state.loading_token === data.id ? true : false
                          }
                        >
                          {this.state.loading_token === data.id ? (
                            <UserButtonLoading color="text-gray-800" />
                          ) : (
                            "Token"
                          )}
                        </button>
                      </div>
                    );
                  } else {
                    button = <div class="text-sm">{col.token}</div>;
                  }
                  return button;
                },
                emaila: (col, data) => {
                  if (data?.email == null || data?.email == "") {
                    var value = "-";
                  } else {
                    var value = data.email;
                  }
                  return (
                    <>
                      <small className="text-small">{value}</small>
                    </>
                  );
                },
                usernamea: (col, data) => {
                  if (data?.username == null || data?.username == "") {
                    var value = "-";
                  } else {
                    var value = data.username;
                  }
                  return (
                    <>
                      <small className="text-small">{value}</small>
                    </>
                  );
                },

                aksi: (col, data) => {
                  var button = (
                    <button
                      onClick={this.showModalDetail.bind(this, data.id)}
                      className="btn-blue text-lg text-white py-1 px-2 rounded-full font-medium mr-1"
                    >
                      <i className="bx bxs-user-detail"></i>
                    </button>
                  );
                  return button;
                },
              }}
              url={this.state.url_table + this.state.institusi_id_select}
              columns={[
                {
                  index: "no_index",
                  label: "No",
                },
                {
                  index: "tahun_ajaran",
                  label: "Tahun Ajaran",
                },
                {
                  index: "kode_pendaftaran",
                  label: "Kode Pendaftaran Siswa",
                },
                {
                  index: "nama_siswa",
                  label: "NAMA SISWA",
                },
                {
                  index: "nama",
                  label: "NAMA ORTU",
                },
                {
                  index: "emaila",
                  label: "EMAIL",
                },
                {
                  index: "username",
                  label: "USERNAME",
                },
                {
                  index: "jenis_orangtua",
                  label: "JENIS ORTU",
                },
                {
                  index: "no_telepon",
                  label: "NO HP",
                },
                {
                  index: "tanggal_lahir",
                  label: "TANGGAL LAHIR",
                },
                {
                  index: "created_at",
                  label: "TANGGAL PENDAFTARAN",
                },
                {
                  index: "status",
                  label: "STATUS",
                },
                {
                  index: "token",
                  label: "TOKEN",
                },
                {
                  index: "aksi",
                  label: "AKSI",
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
